
import { Component, Vue } from "vue-property-decorator";
import TigerSchoolFootBanner from "@/components/Academy/Utility/TigerSchoolFootBanner.vue";
import TigerSchoolBanner from "@/components/Academy/Utility/TigerSchoolBanner.vue";
@Component({
  components: {
    TigerSchoolFootBanner,
    TigerSchoolBanner,
  },
})
export default class EnquireDispatch extends Vue {
  bannerOption = {
    back: "返回師虎學院",
    title: "企業策略聯盟",
    btnText: "申請聯盟",
    linkUrl: "/Academy/CreateDispatch",
    cssProps: {
      backgroundImage: `url(${require("@/assets/banner/dispatchbanner.jpg")})`,
    },
  };
  footbannerOption = {
    btnText: "我有興趣",
    linkUrl: "/Academy/CreateDispatch",
    cssProps: {
      backgroundImage: `url(${require("@/assets/banner/dispatch.jpg")})`,
    },
  };
}
