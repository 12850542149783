import { render, staticRenderFns } from "./TigerSchoolBanner.vue?vue&type=template&id=2c9e4b62&scoped=true"
import script from "./TigerSchoolBanner.vue?vue&type=script&lang=ts"
export * from "./TigerSchoolBanner.vue?vue&type=script&lang=ts"
import style0 from "./TigerSchoolBanner.vue?vue&type=style&index=0&id=2c9e4b62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9e4b62",
  null
  
)

export default component.exports